import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { ApiResponse } from '@utils/models/ApiResponse';
import { NgSelectOption } from '@utils/interface/ngselect.interfaces';
import { ScaNave } from '~models/carga/interface/ScaNave';
import { ScaNaveCustomProjection } from '~models/carga/projection/ScaNaveCustomProjection';
export interface SelectListNave extends ScaNave {
	value: number;
	label: string;
}

export interface Flag {
	country: string;
	flag: string;
}
@Injectable({
	providedIn: 'root',
})
export class ScaNaveService {
	private readonly _urlApiContries = 'https://restcountries.eu/rest/v2';
	private readonly _url = `${environment.HOST_CARGA}/scanaves`;

	constructor(private readonly _http: HttpClient) {}

	private _createFormData(image: File | null | undefined): FormData {
		const form = new FormData();
		if (image) form.append('image', image);
		return form;
	}

	insert(entity: ScaNave): Observable<ScaNave> {
		return this._http.post<ApiResponse<ScaNave>>(`${this._url}`, entity).pipe(map((res) => res.data));
	}

	update(entity: ScaNave): Observable<ScaNave> {
		return this._http.put<ApiResponse<ScaNave>>(`${this._url}`, entity).pipe(map((res) => res.data));
	}

	uploadImage(idNave: number, image?: File | null): Observable<ScaNave> {
		const data = this._createFormData(image);
		return this._http.put<ApiResponse<ScaNave>>(`${this._url}/uploadImage/${idNave}`, data).pipe(map((res) => res.data));
	}

	delete(entity: ScaNave): Observable<ApiResponse> {
		return this._http.delete<ApiResponse<void>>(`${this._url}`, { body: entity });
	}

	findById(id: number): Observable<ScaNave> {
		return this._http.get<ApiResponse<ScaNave>>(`${this._url}/model-se-ro/${id}`).pipe(map((res) => res.data));
	}

	findAll(): Observable<ScaNave[]> {
		return this._http.get<ApiResponse<ScaNave[]>>(`${this._url}/model-se-ro/findAll`).pipe(map((res) => res.data));
	}

	findAllByEstado(estado = true): Observable<ScaNave[]> {
		return this._http.get<ApiResponse<ScaNave[]>>(`${this._url}/model-se-ro/findAllByEstado/${estado}`).pipe(map((res) => res.data));
	}

	findAllByEstadoNgSelect(estado = true): Observable<ScaNave[]> {
		return this._http.get<ApiResponse<ScaNave[]>>(`${this._url}/model-se-ro/findAllByEstadoNgSelect/${estado}`).pipe(map((res) => res.data));
	}

	findCountryFlag(code: string): Observable<Flag> {
		return this._http.get<any>(`${this._urlApiContries}/alpha/${code}`).pipe(
			catchError((err: HttpErrorResponse | any) => {
				return of({
					error: true,
					name: null,
					flag: null,
					message: `Codigo '${code}' no encontrado`,
				});
			}),
			map((res) => {
				return {
					error: res.error ? res.error : false,
					country: res.name,
					flag: res.flag,
					message: res.error ? res.message : null,
				};
			})
		);
	}

	getFlag(code: string): string {
		if (code == null || code == '') return '';
		return `https://flagcdn.com/112x84/${code.toLowerCase()}.png`;
	}

	findAllCustom(): Observable<ScaNaveCustomProjection[]> {
		return this.findAll().pipe(
			map((data) =>
				data.map(
					(d) =>
						({
							...d,
							scaNave: d,
							country: {
								name: d.tbPaisBandera?.nombre ?? 'no encontrado',
								image: d.tbPaisBandera?.codigo ? this.getFlag(d.tbPaisBandera?.codigo) : null,
							},
						} as ScaNaveCustomProjection)
				)
			)
		);
	}

	getSelectList(): Observable<NgSelectOption<ScaNave>[]> {
		return this.findAllByEstadoNgSelect(true).pipe(
			map((data) => {
				return data.map((item) => {
					return {
						...item,
						value: item.idNave,
						label: `${item.codigo} - ${item.nombre}`,
					};
				});
			})
		);
	}

	getSelect(e: ScaNave | null | undefined): NgSelectOption<ScaNave> | null {
		return e
			? {
					...e,
					value: e.idNave,
					label: `${e.codigo} - ${e.nombre}`,
			  }
			: null;
	}
}
